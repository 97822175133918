import { render, staticRenderFns } from "./FormScoreEvaluation.vue?vue&type=template&id=a012a5f0"
import script from "./FormScoreEvaluation.vue?vue&type=script&lang=js"
export * from "./FormScoreEvaluation.vue?vue&type=script&lang=js"
import style0 from "./FormScoreEvaluation.vue?vue&type=style&index=0&id=a012a5f0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports